// Imports REACT
import React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import MainLayout from "../layouts/MainLayout";

import Home from './Home';
import Login from './Login';
import UrlAnalized from './urlAnalized/listado';
import UrlAnalizedDetalles from './urlAnalized/detalles';
import PendingTask from './pendingTask/listado';

export default function RoutesComponent() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<MainLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/urlAnalized" element={<UrlAnalized />} />
                    <Route path="/urlAnalized/:id" element={<UrlAnalizedDetalles />} />
                    <Route path="/pendingTask" element={<PendingTask />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}