// Imports REACT
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
    useParams,
    Link,
    useNavigate,
} from "react-router-dom";
import moment from 'moment';

// Imports Material UI
import {
    Container,
    Grid,
    Button,
    TextField,
} from '@mui/material';

// Imports Components Core
import Loading from "../../../components/Loading";

// Imports Services
import { getById } from "../../../services/urlAnalized";
import { create, getAll } from "../../../services/pendingTask";

import useStyles from "./index.css";


export default function Detalles() {
    const [infoUrl, setInfoUrl] = useState();
    const [screenshot, setScreenshot] = useState();
    const [pendingTasks, setPendingTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAlerta, setShowAlerta] = useState(false);
    const [dataAlerta, setDataAlerta] = useState();

    const classes = useStyles();

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const filters = { urlId: id };

        getAll(filters).then(({ data }) => {
            setPendingTasks(data);
        }).catch((error) => {
            if (error?.response && error.response.status === 403) {
                navigate("/login");
            }

            setDataAlerta({
                variant: 'error',
                texto: `Error API: ${error.message}`
            });

            setShowAlerta(true);
        });

        getById(id).then(({ data }) => {
            data.screenshot = data.screenshots.length ? data.screenshots[data.screenshots.length - 1].value : undefined;
            data.country = data.dns.length ? data.dns[data.dns.length - 1].country : undefined;
            data.serverValue = data.server.length ? data.server[data.server.length - 1].value : undefined;
            data.mimeTypeValue = data.mimeType.length ? data.mimeType[data.mimeType.length - 1].value : undefined;
            data.asn = data.whoIs.length ? data.whoIs[data.whoIs.length - 1].asn : undefined;
            data.externalLink = data.externalLinks.length ? data.externalLinks[data.externalLinks.length - 1] : undefined;
            setInfoUrl(data);            
        }).catch((error) => {
            if (error?.response && error.response.status === 403) {
                navigate("/login");
            }

            setDataAlerta({
                variant: 'error',
                texto: `Error API: ${error.message}`
            });

            setShowAlerta(true);
        }).finally(() => setLoading(false));


    }, [id, navigate]);

    const handleNewScreenshot = useCallback(() => {
        const newTask = {
            "urlId": infoUrl._id,
            "url": infoUrl.url?.domain,
            "type": "screenshot",
            "createDate": moment().toDate(),
            "inProgress": false
        };

        create(newTask).then(() => {
            setDataAlerta({
                variant: 'success',
                texto: 'Tarea creada con exito.'
            });

            setShowAlerta(true);
            setPendingTasks([...pendingTasks, newTask]);
        }).catch((error) => {
            if (error?.response && error.response.status === 403) {
                navigate("/login");
            }

            setDataAlerta({
                variant: 'error',
                texto: `Error API: ${error.message}`
            });

            setShowAlerta(true);
        });
    }, [infoUrl, pendingTasks, setPendingTasks]);

    const pendienteScreenshot = useMemo(() => {
        return pendingTasks?.some((task) => task.type === 'screenshot');

    }, [pendingTasks, infoUrl]);

    const disabledScreenshotButton = useMemo(() => {
        return pendienteScreenshot || infoUrl?.mimeTypeValue !== 'text/html';
    }, [pendienteScreenshot, infoUrl]);

    return (
        <Container>
            <Grid container>
                {loading && <Loading />}
                {infoUrl &&
                    <Grid className={classes.boxMarginBotTop} container spacing={2}>
                        <Grid item xs={12}>
                            <Link to='/urlAnalized'>
                                <Button variant="contained">Volver</Button>
                            </Link>
                        </Grid>
                        <Grid container item xs={8} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="maindomain"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.url?.maindomain}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="domain"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.url?.domain}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="country"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.country}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="server"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.serverValue}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="mimeType"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.mimeTypeValue}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="asn"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.asn}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="source"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.source}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" disabled={disabledScreenshotButton} onClick={handleNewScreenshot}>{pendienteScreenshot ? 'Pendiente Screenshot' : 'Nuevo Screenshot'}</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <a href={infoUrl.screenshot} rel="noreferrer" target="_blank">
                                <img src={infoUrl.screenshot} alt="screenshot" height={300}/>
                            </a>
                        </Grid>
                        <Grid item xs={12}>
                            <a href={infoUrl.externalLink} rel="noreferrer" target="_blank">
                                <TextField
                                    label="Link Resultados"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={infoUrl.externalLink}
                                    margin="dense"
                                    fullWidth
                                />
                            </a>
                        </Grid>
                    </Grid>
                }
            </Grid>

        </Container>
    );
}
