// Imports REACT
import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

// Imports Material UI
import { Container, Grid } from '@mui/material';

// Imports Components Core
import Listado from '../../../components/Listado';
import Loading from "../../../components/Loading";
import Alerta from "../../../components/Alerta";
import ButtonDetalles from "../../../components/ButtonDetalles";

// Imports Services
import { getAll } from '../../../services/pendingTask';

import useStyles from "./index.css";


export default function PendingTask() {
    const [list, setList] = useState({});
    const [loading, setLoading] = useState(true);
    const [showAlerta, setShowAlerta] = useState(false);
    const [dataAlerta, setDataAlerta] = useState({});
    const classes = useStyles();
    const navigate = useNavigate();

    function buttonDetails(field) { return <ButtonDetalles enlace={`/pendingTask/${field}`} /> }

    const columns = [
        {
            name: 'Url',
            selector: row => row.url,
            sortable: true,
        },
        {
            name: 'Tipo',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Creacion',
            selector: row => (row.createDate ? moment(row.createDate).format('DD/MM/YYYY HH:mm') : ''),
            sortable: true,
        },
        {
            name: 'En proceso',
            selector: row => row.inProgress,
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: row => buttonDetails(row._id),
        },
    ];

    function getPendingTasks() {
        setLoading(true);
        getAll({})
            .then((({ data }) => {
                setList(data);

                if (!data.length) {
                    setDataAlerta({
                        variant: 'info',
                        texto: 'no hay datos'
                    });

                    setShowAlerta(true);
                } else {
                    setShowAlerta(false);
                }
            }))
            .catch((error) => {
                if (error?.response && error.response.status === 403) {
                    navigate("/login");
                }

                setDataAlerta({
                    variant: 'error',
                    texto: `Error API: ${error.message}`
                });

                setShowAlerta(true);
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        getPendingTasks();
    }, []);

    const closeAlerta = useCallback(() => {
        setShowAlerta(false);
    }, [setShowAlerta]);

    return (
        <Container>
            <Grid container spacing={2} xs={12}>
                <Grid className={classes.boxMarginBotTop} item xs={12}>
                    {showAlerta &&
                        <Alerta dataAlerta={dataAlerta}
                            closeAlerta={closeAlerta} />}
                    {loading ? <Loading /> : <Listado data={list} columns={columns} />}
                </Grid>
            </Grid>
        </Container>
    );
}

