import React from "react";
/* https://react-data-table-component.netlify.app/?path=/story/getting-started-intro--page*/
import DataTable from 'react-data-table-component';

export default function ListadoPaginado({ data, page, limit, totalRows, handlePerRowsChange, handlePageChange, columns = [] }) {

    return (
        <>
            {data?.length && (
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationDefaultPage={page}
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            )}
        </>
    );
}
