import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    boxMarginBotTop: {
        marginBottom: "10px",
        marginTop: "10px",
    },
});

export default useStyles;
