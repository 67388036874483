// Imports REACT
import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

// Imports Material UI
import { Container, Grid } from '@mui/material';

// Imports Components Core
import ListadoPaginado from '../../../components/ListadoPaginado';
import Loading from "../../../components/Loading";
import Alerta from "../../../components/Alerta";
import ButtonDetalles from "../../../components/ButtonDetalles";

// Imports Components Page
import Filtro from '../filtros';

// Imports Services
import { getAll } from '../../../services/urlAnalized';

import useStyles from "./index.css";


export default function UrlAnalized() {
    const [list, setList] = useState({});
    const [loading, setLoading] = useState(true);
    const [showAlerta, setShowAlerta] = useState(false);
    const [dataAlerta, setDataAlerta] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState({});
    const classes = useStyles();
    const navigate = useNavigate();

    function buttonDetails(field) { return <ButtonDetalles enlace={`/urlAnalized/${field}`} /> }

    const columns = [
        {
            name: 'Url',
            selector: row => row.url.domain,
            sortable: true,
        },
        {
            name: 'Fuente',
            selector: row => row.source,
            sortable: true,
        },
        {
            name: 'Creacion',
            selector: row => (row.createDate ? moment(row.createDate).format('DD/MM/YYYY HH:mm') : ''),
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: row => buttonDetails(row._id),
        },
    ];

    function getUrls(page, limit, filters) {
        setLoading(true);
        getAll(page, limit, filters)
            .then((({ data }) => {
                setList(data);

                if (data.length === 0) {
                    setDataAlerta({
                        variant: 'info',
                        texto: 'no hay datos'
                    });

                    setShowAlerta(true);
                }
                setShowAlerta(false);
            }))
            .catch((error) => {
                if (error?.response && error.response.status === 403) {
                    navigate("/login");
                }

                setDataAlerta({
                    variant: 'error',
                    texto: `Error API: ${error.message}`
                });

                setShowAlerta(true);
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        getUrls(page, limit, filters);
    }, []);

    const handleSearch = useCallback((filters) => {
        setFilters(filters);
        setPage(1);
        getUrls(1, limit, filters);
    }, [page, limit, setFilters]);

    const closeAlerta = useCallback(() => {
        setShowAlerta(false);
    }, [setShowAlerta]);

    const sourceOptions = [
        'URLSCAN', 
        'PHISHTANK', 
        'OPENPHISH', 
        'Raquel ICLOUD/Junk', 
        'Raquel ICLOUD/Correo no deseado',
        'Raquel GMAIL/[Gmail]/Correu brossa' 
    ];
    const estadoOptions = useMemo(() => (list?.data?.length ? [...(new Set(list.data.filter(torneo => torneo.urlState).map(torneo => torneo.urlState)))] : []), [list]);

    const handlePageChange = useCallback((page) => {
        setPage(page);
        getUrls(page, limit, filters);
    }, [page, limit, filters]);

    const handlePerRowsChange = useCallback((newPerPage) => {
        setLimit(newPerPage);
        getUrls(page, newPerPage, filters);
    }, [page, limit, filters]);

    return (
        <Container>
            <Grid container spacing={2} xs={12}>
                <Grid item className={classes.boxSearch} xs={12}>
                    <Filtro search={handleSearch} sourceOptions={sourceOptions} estadoOptions={estadoOptions} />
                </Grid>
                <Grid item xs={12}>
                    {showAlerta &&
                        <Alerta dataAlerta={dataAlerta}
                            closeAlerta={closeAlerta} />}
                    {loading ? <Loading /> : <ListadoPaginado data={list?.data} columns={columns} page={page} limit={limit} totalRows={list?.totalItems} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} />}
                </Grid>
            </Grid>
        </Container>
    );
}

