import { createUseStyles } from 'react-jss';

const Detalles = createUseStyles({
    boxMarginBotTop: {
        marginBottom: "10px",
        marginTop: "10px",
    },
});

export default Detalles;
