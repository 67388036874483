import React from "react";

// Imports Material UI
import { Container, Grid } from '@mui/material';

import useStyles from "./index.css";

export default function Home() {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Grid container>
                <p>PROBANDO</p>
            </Grid>
        </Container>
    );
}
