import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL_API}/api/v1/pendingTasks`;

axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const getAll = (filters) =>
  axios.post(`${baseURL}`, { filters }).then((response) => {
    return response;
  });

export const getById = (id) =>
  axios.get(`${baseURL}/${id}`).then((response) => {
    return response;
  });

export const create = (pendingTask) =>
  axios.post(`${baseURL}/new`, pendingTask).then((response) => {
    return response;
  });

export const updateById = (id, pendingTask) =>
  axios.patch(`${baseURL}/${id}`, pendingTask).then((response) => {
    return response;
  });

export const deleteById = (id) =>
  axios.delete(`${baseURL}/${id}`).then((response) => {
    return response;
  });

export const deleteAll = () =>
  axios.delete(baseURL).then((response) => {
    return response;
  });
